import { Outlet, Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
   const [isActive, setIsActive] = useState(false);
   const location = useLocation();
   useEffect(() => {
      setIsActive(false); // Reset isActive to false when the location changes
   }, [location]);

   const handleToggle = () => {
      setIsActive(!isActive);
   };
   return (
      <>
         {/* <header className={`header py-4 ${isActive ? "active" : ""}`}>
            <div className="container new">
               <div className="row align-items-center">
                  <div className="col-8 col-md-4">
                     <div className="logo">
                        <Link to="/">
                           {" "}
                           <img src="/images/logo.svg" alt="image" />
                        </Link>
                     </div>
                  </div>
                  <div className="col-4 d-md-none d-block">
                     <div className="hamberger-menu " onClick={handleToggle}>
                        <Hamburger toggled={isActive} size={20} toggle={setIsActive} />
                     </div>
                  </div>
                  <div className=" col-md-4 active-mb">
                     <nav class="header-nav">
                        <ul className="d-md-flex justify-content-center mb-0">
                           <li>
                              <Link to="/">Generate</Link>
                           </li>
                           <li>
                              <Link to="/Resource">Resources</Link>
                           </li>
                           <li>
                              <Link to="/Pricing">Pricing</Link>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div className="col-md-4 active-mb mt-md-0 mt-4">
                     <div className="btn-sec d-md-flex justify-content-md-end">
                        <Link to={"/login"} className="btn btn-t me-3">
                           Login
                        </Link>
                        <Link to="" className="btn">
                           Sign Up
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </header> */}
         <Header />
         <Outlet />
         <Footer />
         {/* <footer className="footer py-5 mb-0">
            <div className="container">
               <div className="logo mb-4 pb-2">
                  <img src="/images/logo.svg" alt="imagealt" />
               </div>
               <p>
                  Using AI to improve cost-efficiency and
                  <br /> accessibility of PPC advertising.
               </p>
               <div className="footer-nav mt-4 pt-2 ">
                  <nav>
                     <ul class="d-flex">
                        <li>
                           <a href="">Generate</a>
                        </li>
                        <li>
                           <a href="">Resources</a>
                        </li>
                        <li>
                           <a href="">Pricing</a>
                        </li>
                        <li>
                           <Link to="/contact">Contact</Link>
                        </li>
                     </ul>
                  </nav>
               </div>
               <div className="copy-right-sc">
                  <div className="row">
                     <div className="col-md-6">© 2024 PpccAI. All rights reserved.</div>
                     <div className="col-md-6">
                        <div className="footer-links">
                           <ul class="d-flex justify-content-md-end justify-content-center mt-md-0 mt-4">
                              <li>
                                 <a href="">
                                    <svg xmlns="http://www.w3.org/2000/svg">
                                       <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"></path>
                                    </svg>
                                 </a>
                              </li>
                              <li>
                                 <a href="">
                                    <svg xmlns="http://www.w3.org/2000/svg">
                                       <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path>
                                    </svg>
                                 </a>
                              </li>
                              <li>
                                 <a href="">
                                    <svg xmlns="http://www.w3.org/2000/svg">
                                       <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path>
                                    </svg>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer> */}
      </>
   );
};

export default Layout;
