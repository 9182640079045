import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../Style/Footer.css';
import { AuthFetch2 } from "../lib/auth"; // Adjust this path if different
import useAuth from "../hooks/useAuth"; // Import the useAuth hook if authentication is needed

function Footer() {
   const isAuthenticated = useAuth(); // Ensure this hook returns the authentication state
   const navigate = useNavigate();
   const [url, setUrl] = useState(""); // URL state for the input field
   const [mockupLoading, setMockupLoading] = useState(false);

   const generateMockup = async () => {
     if (!isAuthenticated) return navigate('/login');
     if (url) {
       const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
       try {
         setMockupLoading(true);
         const response = await AuthFetch2('campaign-gen/mockupGen', {
           method: 'POST',
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify({ url: formattedUrl }),
         });
         const data = await response.json();
         if (data?.message) alert(data?.message);
         // Handle mockup data (e.g., display in the component or pass to a parent component)
         setMockupLoading(false);
       } catch (error) {
         console.log(error);
         setMockupLoading(false);
       }
     } else {
       alert('Please enter a valid URL');
     }
   };

   return (
      <footer className="footer">
         <div className="container">
         <div className="toolforfooter nomobile">
                                 <form className="generateform" onSubmit={(e) => {
      e.preventDefault();  // Prevent form from reloading the page
      generateMockup();    // Trigger the button action
   }}>                             
   
                                       <input
type="url"
                                          placeholder="https://www.yourwebsite.com"
                                          className="form-control"
                                          name="site_url"
                                          value={url}
                                          onChange={(e)=>{setUrl(e.target.value)}}
                                             autocomplete="url"

                                       />
                                    
                                    <div className="submit-btn mt-md-0 mt-3 footertoolbtn">
                                       <button className="footertoolbtnecht btn" type="button" onClick={generateMockup}>
                                          Generate ad
                                       </button>
                                    </div>
                                 </form>

            </div>
            <div className="logo mb-4 pb-2">
               <img src="/images/logo.svg" alt="imagealt" />
            </div>
            <div><p className="logo-desc">
               Using AI to improve cost-efficiency and
               <br /> accessibility of PPC advertising.
            </p></div>
            <div className="footer-nav mt-4 pt-2 ">
               <nav>
                  <ul class="d-flex">
                     <li>
                        <Link to="/">Generate</Link>
                     </li>
                     <li>
                        <Link to="/resources">Resources</Link>
                     </li>
                     <li>
                        <Link to="/pricing">Pricing</Link>
                     </li>
                     <li>
                        <a href="mailto:support@ppcc.ai">Contact</a>
                     </li>
                  </ul>
               </nav>
            </div>
            <div className="copy-right-sc">
               <div className="row">
                  <p className="col-md-6 copyright-text">© 2024 PpccAI. All rights reserved.</p>
                  <div className="col-md-6">
                     <div className="footer-links">
                        <ul class="d-flex justify-content-md-end justify-content-center mt-md-0 mt-4">
                           <li>
                              <a href="https://x.com/@aippctool" target="_blank">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-twitter-x">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
                              </a>
                           </li>
                           <li>
                              <a href="https://www.linkedin.com/company/ppccai" target="_blank">
                                 <svg xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"></path>
                                 </svg>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default Footer;
